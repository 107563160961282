import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cpt,
    earningToken: serializedTokens.cpt,
    contractAddress: {
      271271: '',
      271: '0x0Cc6b97cC115d898D3f3703D58EAe00B5753285e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 0,
    isFinished: true,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.cpt,
    earningToken: serializedTokens.cpt,
    contractAddress: {
      271271: '',
      271: '0xcF241945C06e3d7778f06309fa22c0F36035293a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.wbnb,
    earningToken: serializedTokens.cpt,
    contractAddress: {
      271271: '',
      271: '0xaf20797d74d099ad5be82a30e93340422a0a9d8a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
  },
]

export default pools
