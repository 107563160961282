import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Heading, Text, Flex } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const ComingSoonContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 50px; /* Add margin from the top */
  animation: ${fadeIn} 1.5s ease-in-out;
`

const AnimatedText = styled(Text)`
  font-size: 48px; /* Increased font size for larger text */
  font-weight: 700; /* Make the text bold */
  color: ${({ theme }) => theme.colors.secondary};
  animation: ${fadeIn} 2s ease-in-out infinite alternate; /* Add infinite animation effect */
`

const Pools: React.FC = () => {
  return (
    <>
      <PageHeader>
        <Heading as="h1" scale="xxl" color="secondary" mb="24px">
          Pools
        </Heading>
      </PageHeader>
      <Page>
        <ComingSoonContainer>
          <AnimatedText>
            Staking Pools is Coming Soon..
          </AnimatedText>
        </ComingSoonContainer>
      </Page>
    </>
  )
}

export default Pools

