import tokens from './tokens'
import { SerializedFarmConfig } from './types'

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: null,
    lpSymbol: 'CPT-EGON LP',
    lpAddresses: {
      271271: '',
      271: '0x982709A084EF09C523C2b2eB24d6eDA28217F178',
    },
    token: tokens.cpt,
    quoteToken: tokens.wbnb,
  },

  {
    pid: null,
    lpSymbol: 'EGON-USDT LP',
    lpAddresses: {
      271271: '',
      271: '0x03717040F40def71Da05A45DC60dcA7A4F325658',
    },
    token: tokens.wbnb,
    quoteToken: tokens.usdt,
  }
]


export default priceHelperLps
